.h-60px {
  height: 50px !important;
}
.w-60px {
  width: 50px !important;
}

.min100vh{
  min-height: 100vh !important;
}

html{
  overflow-x: hidden;
}


.poweredby{
 text-align: center;
            padding: 10px;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
}

 .custom-list-item {
   margin-bottom: 1.5rem;
    position: relative;
    box-shadow: 0 0 0.25rem rgba(73,80,87,.05);
  }
  
  .custom-item-name {
    font-weight: bold;
    color: #FF0000; /* Red color for item names */
  }
  
  .custom-item-details {
    color: #0000FF; /* Blue color for item details */
  }

  .text-right{
    text-align: right !important;
  }

.loaderdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #7D62EF;

    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 


     /* Custom styles for the list and checkout section */
  .checkout-list {
    border: none;
    background-color: transparent;
    list-style-type: none;
    padding: 0;
  }
  
  .checkout-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
  }
    .checkout-list li:last-child {
    border-bottom: none;
  }
  
  .checkout-list li:last-child {
    border-bottom: none;
  }
  
  .checkout-total {
    font-weight: bold;
  }
  
  .checkout-balance {
    color: #28a745;
    font-weight: bold;
  }

   .checkout-balance {
    color: #28a745;
    font-weight: bold;
  }


  .float-magic{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	/* color:#FFF; */
	/* border-radius:50px; */
	text-align:center;
  /* font-size:30px; */
	/* box-shadow: 2px 2px 3px #999; */
  z-index:100;
}

.smile-text {
  text-path: path('M 50 100 Q 100 200 150 100 Q 200 0 250 100 Q 300 200 350 100');
  font-size: 10px;
  color: #000
}